<template>
	<div class="logo">
		<router-link :to="url">
			<img class="logo-light" :src="logoUrl" alt="Corporate Logo" />
			<img class="logo-dark" :src="logoUrl" alt="Corporate Logo" />
		</router-link>
	</div>
</template>

<script>
	import Axios from "axios";
	export default {
		name: 'Logo',
		props: {
			url: {
				type: String,
				default: '/travel-agency'
			},
			lightLogo: {
				type: String,
				default: function() {
					return require(`@/assets/images/logo/logo.png`)
				}
			},
			darkLogo: {
				type: String,
				default: function() {
					return require(`@/assets/images/logo/logo-dark.png`)
				}
			}
		},
		data() {
			return {
				logoUrl: ''
			}
		},
		created() {
			let _this = this
			Axios.post(Axios.defaults.baseURL + '/system/about/getAbout', {}).then((res) => {
				console.log(res)
				console.log(res.data.extend.list)

				_this.logoUrl = res.data.extend.list[0].logo;
				
			});
		}
	}
</script>
