<template>
    <!-- Start Copy Right Area  -->
    <div class="copyright-area copyright-style-one">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-8 col-sm-12 col-12">
                    <div class="copyright-left">
                        <ul class="ft-menu link-hover">
                            <li>
                                <!-- <router-link to="#">蒙ICP00000000号</router-link> -->
								<a href="https://beian.miit.gov.cn">
									蒙ICP备2023000807号-1
								</a>
                            </li>
                            <li>
								<a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=15060202000474">
									蒙公网安备 15060202000474号
								</a>
                            </li>
                          <!--  <li>
                                <router-link to="/contact">联系我们</router-link>
                            </li> -->
							<li>
							    <p class="copyright-text">技术支持：助梦科技15049882649</p>
							</li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-6 col-md-4 col-sm-12 col-12">
                    <div class="copyright-right text-center text-lg-end">
                        <p class="copyright-text">© 鄂尔多斯市品牌标准化促进会 {{ new Date().getFullYear() }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- End Copy Right Area  -->
</template>

<script>
    export default {
        name: 'Copyright'
    }
</script>