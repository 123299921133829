<template>
	<Layout>
		<!-- Start Contact Area  -->
		<div class="rwt-contact-area rn-section-gap">
			<div class="container">
				<div class="row">
					<div class="col-lg-12 mb--40">
						<SectionTitle text-align="center" subtitle="Contact Form" title="联系信息" />
					</div>
				</div>
				<div class="row row--15">
					<div class="col-lg-12">
						<div class="rn-contact-address mt_dec--30">
							<div class="row">
								<div class="col-lg-4 col-md-6 col-12">
									<div class="rn-address">
										<div class="icon">
											<Icon name="headphones" :size="40" />
										</div>
										<div class="inner">
											<h4 class="title">联系电话</h4>
											<p><a href="contactInfo.phone">{{contactInfo.phone}}</a></p>
											<p><a href="contactInfo.phone1">{{contactInfo.phone1}}</a></p>
										</div>
									</div>
								</div>

								<div class="col-lg-4 col-md-6 col-12">
									<div class="rn-address">
										<div class="icon">
											<Icon name="mail" :size="40" />
										</div>
										<div class="inner">
											<h4 class="title">联系邮箱</h4>
											<p><a href="">{{contactInfo.email}}</a></p>
											<p><a href="">{{contactInfo.email1}}</a></p>
										</div>
									</div>
								</div>

								<div class="col-lg-4 col-md-6 col-12">
									<div class="rn-address">
										<div class="icon">
											<Icon name="map-pin" :size="40" />
										</div>
										<div class="inner">
											<h4 class="title">联系地址</h4>
											<p>{{contactInfo.address}}</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="row mt--40 row--15">
					<div class="col-lg-7">
						<ContactForm />
					</div>
					<div class="col-lg-5 mt_md--30 mt_sm--30">
						<GoogleMap />
					</div>
				</div>
			</div>
		</div>
		<!-- End Contact Area  -->
	</Layout>
</template>

<script>
	import Layout from '../components/common/Layout'
	import Breadcrumb from '../components/elements/breadcrumb/Breadcrumb'
	import SectionTitle from '../components/elements/sectionTitle/SectionTitle'
	import Icon from "../components/icon/Icon";
	import GoogleMap from "../components/elements/contact/GoogleMap";
	import ContactForm from "../components/elements/contact/ContactForm";
	import Axios from "axios";
	export default {
		name: 'ContactPage',
		components: {
			ContactForm,
			GoogleMap,
			Icon,
			SectionTitle,
			Breadcrumb,
			Layout
		},
		data() {
			return {
				contactInfo: {}
			}
		},
		created() {
			let _this = this
			console.log('created')
			_this.$nextTick(() => {
				Axios.post(Axios.defaults.baseURL + '/system/contact/getContact', {}).then((res) => {
					console.log(res)
					console.log(res.data.extend.list)

					_this.contactInfo = res.data.extend.list[0];
					console.log(_this.contactInfo)
				});
			})

		}
	}
</script>
