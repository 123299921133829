<template>
    <!-- Start Google MAp -->
    <div class="google-map-style-1">
        <!-- <iframe src="https://map.baidu.com/@12242618.115025274,4812545.7536134645,18.65z" width="600" height="550" style="border:0;" allowfullscreen="" loading="lazy"></iframe> -->
    <div id="map" class="wow zoomIn" style="width: 100%; height: 83%;"></div>
	</div>
    <!-- End Google MAp -->
</template>

<script>
	
	import BMap from "BMap";
    export default {
        name: 'BMap',
		mounted() {
		  var map = new BMap.Map("map"); // 创建地图实例
		  var point = new BMap.Point(109.976565,39.822259); // 创建点坐标
		  map.centerAndZoom(point, 18); // 初始化地图，设置中心点坐标和地图级别
		  map.enableScrollWheelZoom(true); //开启鼠标滚轮缩放
		  var marker = new BMap.Marker(point); // 创建标注
		  map.addOverlay(marker); // 将标注添加到地图中
		  var opts = {
		    width: 50, // 信息窗口宽度
		    height: 10, // 信息窗口高度
		    title: "" // 信息窗口标题
		  };
		  var infoWindow = new BMap.InfoWindow(
		    "鄂尔多斯市品牌标准化促进会",
		    opts
		  ); // 创建信息窗口对象
		  map.openInfoWindow(infoWindow, map.getCenter()); // 打开信息窗口
		  var wow = new WOW();
		  wow.init();
		}
    }
</script>