<template>
	<ul class="mainmenu">
		<li class="has-droupdown has-menu-child-item">
			<router-link to="/travel-agency">首页</router-link>
		</li>
		<li>
			<router-link to="/about">关于我们</router-link>
		</li>
		<li class="with-megamenu has-menu-child-item">
			<router-link :to="{path:'/blog-details',query:{id:`gzzd`}}">规章制度</router-link>
		</li>
		<li class="has-droupdown has-menu-child-item">
			<router-link to="/blog-list-sidebar">综合新闻</router-link>
		</li>
		<li class="has-droupdown has-menu-child-item">
			<router-link to="/portfolio-three-column">品牌企业</router-link>
		</li>
		<!-- <li>
			<router-link :to="{path:'/blog-details',query:{id:`fwxm`}}">服务项目</router-link>
		</li>
		<li>
			<router-link to="/blog-grid/?type=xmhz">项目合作</router-link>
		</li> -->
		<li>
			<router-link to="/blog-list-sidebar1">品牌活动</router-link>
		</li>
		<li>
			<router-link to="/blog-list-sidebar2">品牌学习</router-link>
		</li>
		<li>
			<router-link to="/blog-list-sidebar3">品牌人物</router-link>
		</li>
		<li>
			<router-link to="/contact">联系我们</router-link>
		</li>

	</ul>
</template>

<script>
	import Icon from "../../icon/Icon";
	export default {
		name: 'Nav',
		components: {
			Icon
		}
	}
</script>
