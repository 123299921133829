<template>
    <form class="contact-form-1" action="" @submit="sendEmail">
        <div class="form-group">
            <input
                type="text"
                name="name"
                placeholder="请输入姓名"
				v-model="form.name"
                required
            />
        </div>

        <div class="form-group">
            <input
                type="text"
                name="phone"
                placeholder="请输入电话"
				v-model="form.phone"
                required
            />
        </div>

        <div class="form-group">
            <input
                type="text"
                name="email"
                placeholder="请输入邮箱"
				v-model="form.email"
                required
            />
        </div>


        <div class="form-group">
            <input
                type="text"
                name="content"
                placeholder="请输入内容"
				v-model="form.content"
                required
            />
        </div>

   <!--     <div class="form-group">
                <textarea
                    name="message"
                    placeholder="Your Message"
					v-model="form.name"
                    required>
                </textarea>
        </div> -->

        <div class="form-group">
            <button class="btn-default btn-large" @click="submit">提交</button>
        </div>

        <div class="form-group" v-html="showResult ? result : null"/>
    </form>
</template>

<script>
	import Axios from "axios";
    export default {
        name: 'ContactForm',
        data() {
            return {
				form: {
				  name: '',
				  email: '',
				  phone: '',
				  content: ''
				},
                showResult: false,
                result: `<p class="success-message">Your Message has been successfully sent. I will contact you soon.</p>`
            }
        },
        methods: {
            sendEmail() {
				console.log(333333333)
                // this.showResult = true
            },
			submit(){
				let _this = this
				let formData = {
				  name: this.form.name,
				  email: this.form.email,
				  content: this.form.content,
				  phone: this.form.phone
				};
				console.log(formData)
				console.log(6666666)
				Axios.post(Axios.defaults.baseURL + '/system/contactUs/saveContactUs', {
					params: {
						name: _this.form.name,
						email: _this.form.email,
						content: _this.form.content,
						phone: _this.form.phone
					}
				}).then((res) => {
					console.log('请求完成')
					console.log(res)
					
				});
			}
        }
    }
</script>