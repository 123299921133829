<template>
	<aside class="rwt-sidebar">
		<!-- <div class="rbt-single-widget widget_search mt--40">
            <div class="inner">
                <form class="blog-search" action="#">
                    <input type="text" placeholder="Search ..."/>
                    <button class="search-button">
                        <Icon name="search" size="20"/>
                    </button>
                </form>
            </div>
        </div> -->

		<!-- Start Single Widget -->
		<!-- <div class="rbt-single-widget widget_categories mt--40">
            <h3 class="title">Categories</h3>
            <div class="inner">
                <BlogCategories/>
            </div>
        </div> -->
		<!-- End Single Widget -->

		<!-- Start Single Widget -->
		<div class="rbt-single-widget widget_recent_entries mt--40">
			<h3 class="title">热门活动</h3>
			<div class="inner">
				<!-- <BlogSidebarPost/> -->
				<ul>
					<!-- <router-link class="image" :to="{path:'/blog-details',query:{id:`${blog.id}`,flag:`${blog.flag}`}}">
						{{ blog.title }}
					</router-link> -->
					<li v-for="(blog, index) in hotNews">
						<router-link class="d-block" :to="{path:'/blog-details',query:{id:`${blog.id}`}}">
							{{ blog.title }}
							<div class="cate" style="color: gray;">{{blog.publishedAt}}</div> 
						</router-link>
					</li>
					<!-- <li v-for="(blog, index) in hotNews">
						<a :href="'/blog-details?id='+blog.id" class="d-block">{{blog.title}}</a>
						<span class="cate">{{blog.publishedAt}}</span>
					</li> -->
					<!-- <li><a href="#/blog-list-sidebar#" class="d-block">Should Fixing Corporate Take 100 Steps.</a><span
							class="cate">Company</span></li>
					<li><a href="#/blog-list-sidebar#" class="d-block">Top 5 Lessons About Corporate To Learn
							Before.</a><span class="cate">UX Design</span></li> -->
				</ul>
			</div>
		</div>
		<!-- End Single Widget -->

		<!-- Start Single Widget -->
		<!-- <div class="rbt-single-widget widget_tag_cloud mt--40">
            <h3 class="title">Tags</h3>
            <div class="inner mt--20">
                <BlogTags/>
            </div>
        </div> -->
		<!-- End Single Widget -->
	</aside>
</template>

<script>
	import Icon from '../icon/Icon'
	import BlogCategories from './BlogCategories'
	import BlogSidebarPost from './BlogSidebarPost'
	import BlogSidebarArchive from './BlogSidebarArchive'
	import BlogTags from './BlogTags'
	import Axios from "axios";
	export default {
		name: 'BlogSidebar',
		components: {
			BlogTags,
			BlogSidebarArchive,
			BlogSidebarPost,
			BlogCategories,
			Icon
		},
		data() {
			return {
				hotNews:[]
			}
		},
		created() {
			let _this = this
			console.log('created')
			_this.$nextTick(() => {
				
				Axios.get(Axios.defaults.baseURL + '/system/news/getHotNews', {
					params: {
						classId: 1
					}
				}).then((res) => {
					console.log(res)
					console.log(res.data.extend.list)
					_this.hotNews = res.data.extend.list;
				});
				
				// Axios.post(Axios.defaults.baseURL + '/system/news/getHotNews', {}).then((res) => {
				// 	console.log(res)
				// 	console.log(res.data.extend.list)
				// 	_this.hotNews = res.data.extend.list;
				// });
			})

		}
	}
</script>
