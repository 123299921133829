<template>
	<Layout header-class="header-transparent" :show-newsletter="true" :show-cta="false">
		<!-- Start Slider Area -->
		<div class="slider-area slider-style-1 height-850 bg_image" data-black-overlay="7"
			:style="{'background-image': `url(${topBackgroundImg})`}">
			<div class="container">
				<div class="row">
					<div class="col-lg-12">
						<div class="inner pt--80 text-center">
							<!-- <div>
								<h3 class="rn-sub-badge" data-aos="fade-up" data-aos-delay="100">
									<span class="theme-gradient">Our Company's About Details.</span>
								</h3>
							</div> -->
							<!-- <h1 class="title display-one" data-aos="fade-up" data-aos-delay="150">
								We are a Corporate <br /> Business Agency.
							</h1> -->
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- End Slider Area -->

		<!-- Start Service Area -->
		<div class="service-area rn-section-gapTop">
			<div class="container">
				<div class="row">
					<div class="col-lg-6">
						<div class="content" data-aos="fade-up">
							<h3 class="title">鄂尔多斯市品牌标准化促进会</h3>
						</div>
					</div>
					<div class="col-lg-6">
						<p class="mb--10" data-aos="fade-up">
							2021年10月，党中央、国务院印发《国家标准化发展纲要》，这是以习近平同志为核心的党中央立足国情、放眼全球、面向未来作出的重大决策，树起了标准引领经济社会高质量发展的重要里程碑。标准是质量发展的基础，是品牌建设的支撑：品牌是高质量发展的重要象征，是赢得国际市场的重要资源，也是国家竞争力的重要体现。以品牌为目标，以标准为引领，是全社会推动高质量发展、满足人民美好生活需要的共同使命。
							鄂尔多斯市品牌标准化促进会在鄂尔多斯市工商联指导下，市民政局主管下2023年1月正式成立，是由从事品牌、标准化建设相关企事业单位和个人自愿结成的全市性、行业性、非营利性社会团体。在商标、品牌与标准化建设领域为会员单位提供更加全面、精准、专业化的服务。
						</p>
					</div>
				</div>
			</div>
		</div>
		<!-- End Service Area -->

		<!-- Start Brand Area -->
		<!--  <div class="rwt-brand-area pb--60 pt--30">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12 mt--10">
                        <Brand :brand-list="brandList" :brand-style="3"/>
                    </div>
                </div>
            </div>
        </div> -->
		<!-- End Brand Area -->

		<Separator />

		<!-- Start Service Area -->
		<div class="rn-service-area rn-section-gap">
			<div class="container">
				<div class="row">
					<div class="col-lg-12">
						<SectionTitle text-align="center" subtitle="nine service sectors" title="九大服务板块"
							:description="aboutInfo.nineServices" data-aos="fade-up" />
					</div>
				</div>
				<ServiceOne service-style="service__style--1 bg-color-blackest radius mt--25" icon-size="62"
					text-align="center" />
			</div>
		</div>
		<!-- End Service Area -->

		<!-- <div class="rwt-timeline-area rn-section-gapBottom">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <Timeline :timeline-data="timelineData" :timeline-style="3"/>
                    </div>
                </div>
            </div>
        </div> -->

		<Separator />

		<AboutFour :image="require(`@/assets/images/about/contact-image.jpg`)" />

		<!-- Start Elements Area -->
		<!-- <div class="rwt-counterup-area pb--100">
            <div class="container mt_dec--30">
                <Counter
                    :counter-style="5"
                    text-align="center"
                    :counter-data="counterData"
                    column="col-lg-3 col-md-6 col-sm-6 col-12"
                />
            </div>
        </div> -->
		<!-- End Elements Area -->

		<Separator />

		<!-- Start Elements Area -->
		<!-- <div class="rwt-team-area rn-section-gap">
			<div class="container">
				<div class="row mb--20">
					<div class="col-lg-12">
						<SectionTitle text-align="center" subtitle="Our Corporate Team." title="Corporate Team Member."
							data-aos="fade-up" />
					</div>
				</div>
				<div class="row">
					<div class="col-lg-6 col-xl-3 col-md-6 col-12 mt--30" v-for="(teamMember, index) in teamData"
						:key="index">
						<Team :team-member="teamMember" :team-style="4" />
					</div>
				</div>
			</div>
		</div> -->
		<!-- End Elements Area -->
	</Layout>
</template>

<script>
	import Layout from '../components/common/Layout'
	import Separator from '../components/elements/separator/Separator'
	import SectionTitle from '../components/elements/sectionTitle/SectionTitle'
	import AboutFour from '../components/elements/about/AboutFour'
	import ServiceOne from '../components/elements/service/ServiceOne'
	import Timeline from '../components/elements/timeline/Timeline'
	import Counter from '../components/elements/counterUp/Counter'
	import Brand from '../components/elements/brand/Brand'
	import Team from '../components/elements/team/Team'
	import Axios from "axios";
	export default {
		name: 'About',
		components: {
			Team,
			Brand,
			Counter,
			Timeline,
			ServiceOne,
			AboutFour,
			SectionTitle,
			Separator,
			Layout
		},
		data() {
			return {
				brandList: [{
						image: require(`@/assets/images/brand/brand-01.png`)
					},
					{
						image: require(`@/assets/images/brand/brand-02.png`)
					},
					{
						image: require(`@/assets/images/brand/brand-03.png`)
					},
					{
						image: require(`@/assets/images/brand/brand-04.png`)
					},
					{
						image: require(`@/assets/images/brand/brand-05.png`)
					},
					{
						image: require(`@/assets/images/brand/brand-06.png`)
					},
					{
						image: require(`@/assets/images/brand/brand-07.png`)
					},
					{
						image: require(`@/assets/images/brand/brand-08.png`)
					},
					{
						image: require(`@/assets/images/brand/brand-01.png`)
					},

				],
				timelineData: [{
						id: '1',
						title: 'Knowledge',
						description: 'Present all available features in Essentials.',
					},
					{
						id: '2',
						title: 'Working',
						description: 'All Feature available features in Essentials.',
					},
					{
						id: '3',
						title: 'Solution',
						description: 'Popular Feature available features in Essentials.',
					},
					{
						id: '4',
						title: 'Process',
						description: 'Latest Feature available features in Essentials.',
					},
				],
				counterData: [{
						number: 199,
						title: 'Happy Clients',
					},
					{
						number: 575,
						title: 'Employees',
					},
					{
						number: 69,
						title: 'Useful Programs',
					},
					{
						number: 500,
						title: 'Useful Programs',
					}
				],
				teamData: [{
						image: 'team-dark-01',
						name: 'Sr Janen Sara',
						designation: 'Sr Product Designer',
						location: 'CO Miego, AD, USA',
						description: 'Yes, I am a product designer. I have a passion for product design.'
					},
					{
						image: 'team-dark-02',
						name: 'Corporate Jane',
						designation: 'Manager',
						location: 'Bangladesh',
						description: 'Yes, I am a product designer. I have a passion for product design.'
					},
					{
						image: 'team-dark-03',
						name: 'Jara Saraif',
						designation: 'Software Developer',
						location: 'Poland',
						description: 'Yes, I am a product designer. I have a passion for product design.'
					},
					{
						image: 'team-dark-04',
						name: 'Afanan Sifa',
						designation: 'Accounts Manager',
						location: 'Poland',
						description: 'Yes, I am a product designer. I have a passion for product design.'
					},
				],
				topBackgroundImg: '',
				aboutInfo: {}
			}
		},
		created() {
			let _this = this
			console.log('created')
			_this.$nextTick(() => {
				Axios.post(Axios.defaults.baseURL + '/system/about/getAbout', {}).then((res) => {
					console.log(res)
					console.log(res.data.extend.list)


					_this.aboutInfo = res.data.extend.list[0];
					console.log(_this.aboutInfo)
				});

				Axios.post(Axios.defaults.baseURL + '/system/adoutImg/getAdoutImg', {}).then((res) => {
					console.log("顶部大图")
					console.log(res)
					console.log(res.data.extend.list)


					_this.topBackgroundImg = res.data.extend.list[0].img;
					console.log(_this.aboutInfo)
				});
			})

		}
	}
</script>
